import React from 'react'
import styled from 'styled-components'
import Jumbotron from 'src/components/Homepage/Jumbotron'
import Certified from 'src/components/Homepage/Certified'
import BeTheHero from 'src/components/Homepage/BeTheHero'
import GetStarted from 'src/components/Homepage/GetStarted'
import TheFinnWay from 'src/components/Homepage/TheFinnWay'
import Reviews from 'src/components/Homepage/Reviews'
import VetEndorsement from 'src/components/shared/VetEndorsement'
import PressBanner from 'src/components/shared/PressBanner'
import { Singles, Bundles, BestSellers } from 'src/components/OurProducts'
import Plaque from 'src/assets/img/homepage/dental-chews.jpg'
import Plaque_mobile from 'src/assets/img/homepage/dental-chews_mobile.jpg'

import { media } from 'src/styles/util'

const PlaqueImgDesktop = styled.img`
  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: block;
  `}
`

const PlaqueImgMobile = styled.img`
  ${media.mobile`
    display: block;
  `}
  ${media.desktop`
    display: none;
  `}
`

const Homepage = () => (
  <>
    <Jumbotron />
    <PressBanner bg="lightBlue" color="navy" homepage ticking />
    <a href="/products/plaque-patrol-dental-chews-large">
      <PlaqueImgDesktop src={Plaque} alt="Dental Chews" width="100%" />
      <PlaqueImgMobile src={Plaque_mobile} alt="Dental Chews" width="100%" />
    </a>
    <Singles title="Shop Supplements" buttonCopy="Shop Now" isHomePage sustain />
    <Certified />
    <BestSellers title="Shop Best Sellers" buttonCopy="Shop Now" isHomePage sustain />
    <GetStarted />
    <Bundles title="Save on Bundles" buttonCopy="Shop Now" isHomePage />
    <TheFinnWay />
    <VetEndorsement />
    <Reviews />
  </>
)

export default Homepage
