import React, { useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'

import { H1 } from 'src/components/shared/text'
import { PrimaryButton } from 'src/components/shared/buttons'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

import findTheRightSupplementSvg from 'src/assets/img/homepage/find-the-right-supplement.svg'
import findTheRightSupplementMobileSvg from 'src/assets/img/homepage/find-the-right-supplement-mobile.svg'

const Section = styled.section`
  position: relative;
  background-color: ${colors.pink};
  display: grid;
  place-items: center;
  ${media.tablet`
    min-height: 700px;
  `}
  ${media.desktop`
    min-height: 85vh;
  `}
`

const Grid = styled.div`
  width: 100%;
  margin: 0 auto;

  display: grid;
  align-items: center;

  padding: 0;

  ${media.tablet`
    justify-items: start;
    grid-template-columns: minmax(auto, 400px) 1fr;
    padding: 180px 40px 150px 40px;
  `}
  ${media.desktop`
    max-width: 1300px;
    grid-template-columns: 1.25fr 1fr;
    padding: 240px 86px 150px 86px;
  `}
`

const TextContainer = styled.div`
  z-index: 2;
  width: 100%;

  display: grid;
  justify-items: center;
  align-items: center;
  grid-row-gap: 37px;
  text-align: center;

  ${media.tablet`
    justify-items: start;
    text-align: left;
  `}
  ${media.desktop`
    max-width: 590px;
    grid-row-gap: 43px;
  `}
`

const StyledH1 = styled(H1)`
  visibility: hidden;
  color: #161345;

  ${media.mobile`
    display:none;
  `}
  ${media.tablet`
    font-size: 60px;
    line-height: 70px;
    text-align: left;
    max-width: 100%;
    display: block;
  `}
  ${media.desktop`
    font-size: 96px;
    line-height: 100px;
    max-width: 100%;
    text-align: left;
    display: block;
  `}
`

const LifestyleImg = styled.div`
  visibility: hidden;
  position: absolute;
  right: 0;

  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    width: 60vw;
    left: auto;
    bottom: 50px;
    right: 0;
    display: block;
  `}
  ${media.desktop`
    transform: translate(0, -50%);
    width: 48vw;
    bottom: auto;
    top: 62%;
    max-width: 700px;
    display: block;
  `}
`

const LifestyleImgMobile = styled.div`
  position: relative;

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 13%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    a {
      flex: 1;
      padding: 20px;
      text-indent: -9999px;
    }
  }

  ${media.mobile`
    display: block;
    padding-top:60px;
  `}

  ${media.tablet`
    display: none;
  `}

  ${media.desktop`
    display: none;
  `}
`

const ButtonGrid = styled.div`
  visibility: hidden;
  position: relative;

  place-items: center;
  grid-gap: 10px;
  grid-template-columns: auto auto;
  display: none;

  ${media.tablet`
    grid-gap: 17px;
    display: grid;
  `}
  ${media.desktop`
    grid-template-columns: auto auto;
    margin-top: 19px;
    display: grid;
  `}

  & button {
    visibility: hidden;
    min-width: 233px;
    min-height: 67px;
    & p {
      font-size: 26px;
    }
  }
`

const FindTheRightSupplementDesktop = styled.img`
  visibility: hidden;
  position: absolute;
  transform: translate(100%, -90%);

  top: 51%;
  right: 0;

  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: block;
  `}
`

const FindTheRightSupplementMobile = styled(FindTheRightSupplementDesktop)`
  visibility: hidden;
  transform: translate(50%, 100%);

  top: auto;
  bottom: -6px;

  ${media.mobile`
    display: block;
    right: 115px;
  `}
  ${media.tablet`
    right: 130px;
  `}
  ${media.desktop`
    display: none;
  `}
`

const query = graphql`
  query {
    lifestyle: file(relativePath: { regex: "/affiliate/lifestyle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
    hero: file(relativePath: { regex: "/homepage/hero-2025.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
  }
`

const Jumbotron = () => {
  const { lifestyle, hero } = useStaticQuery(query)

  useEffect(() => {
    const tl = gsap.timeline({ delay: 0.25 })
    const classNames =
      '.jumbotron__title, .jumbotron__cta, .jumbotron__quiz-text, .jumbotron__lifestyle'
    tl.to('.jumbotron__cta-grid', { autoAlpha: 1 })
    tl.to(classNames, { visibility: 'visible' })
    tl.from('.jumbotron__title', { opacity: 0, y: 20, duration: 0.35 })
    tl.from('.jumbotron__lifestyle', { opacity: 0, duration: 0.5 }, '<')
    tl.from('.jumbotron__cta', {
      opacity: 0,
      scale: 0.75,
      duration: 0.25,
      stagger: 0.25,
      ease: 'linear'
    })
    tl.from('.jumbotron__quiz-text', { opacity: 0, duration: 0.35, stagger: 0.25 })
  }, [])

  const handleClick = (destination: string) => {
    navigate(destination)
  }

  return (
    <Section>
      <Grid>
        <TextContainer>
          <StyledH1 className="jumbotron__title">
            Trusted Nutrition for Your Trusty Companion
          </StyledH1>
          <LifestyleImgMobile>
            <GatsbyImage
              image={getImage(hero)}
              alt="Trusted Nutrition for Your Trusty Companion"
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
            <div className="button-container">
              <a href="/products" className="button">
                Shop Now
              </a>
              <a href="/consultation" className="button">
                Take Quiz
              </a>
            </div>
          </LifestyleImgMobile>
          <ButtonGrid className="jumbotron__cta-grid">
            <PrimaryButton
              copy="Shop Now"
              arrow
              onClick={() => handleClick('/products')}
              className="jumbotron__cta"
            />
            <PrimaryButton
              copy="Take Quiz"
              arrow
              onClick={() => handleClick('/consultation')}
              className="jumbotron__cta"
            />
            <FindTheRightSupplementDesktop
              src={findTheRightSupplementSvg}
              alt="Find the right supplement"
              className="jumbotron__quiz-text"
            />
            <FindTheRightSupplementMobile
              src={findTheRightSupplementMobileSvg}
              alt="Find the right supplement"
              className="jumbotron__quiz-text"
            />
          </ButtonGrid>
        </TextContainer>
        <div />
      </Grid>
      <LifestyleImg className="jumbotron__lifestyle">
        <GatsbyImage
          image={getImage(lifestyle)}
          alt="Woman with dog"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </LifestyleImg>
    </Section>
  )
}

export default Jumbotron
